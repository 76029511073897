import {fromEvent} from "rxjs";
import {sdkPostMessage} from "./util";
import {IMenuItem} from "./types/menu.interface";
import {IUserAppStateEntity} from "./types/user-app-state.interface";
import {IPlatformInteractionsInterface} from "./types/platform-interactions.interface";

export const platform = {
  init(){
    return new Promise((resolve) => {
      sdkPostMessage("platform", "init",  'investec-web-platform')
        .then((res) => {
          if(res.data === undefined){
            const hostUrl = window.location.host;
            if(hostUrl.includes('ng') || hostUrl.includes('wp') || hostUrl.includes('localhost')){
              window.location.href = 'https://loginstg.secure.investec.com/wpaas/dev-mode'+ '?ZGVzdGluYXRpb24=' + btoa(window.location.host + (window.location.pathname === '/' ?  '' : window.location.pathname))
            } else {
              fetch('https://login.secure.investec.com/invsy-redirect/'+ window.location.href,{
                method: 'GET',
                cache: 'no-cache'
              }).then(res => res.json()).then(res => {
                console.log('redirected to ' + res.redirectUrl)
                window.location.href = res.redirectUrl
              }).catch(err => {
                console.log(err)
              })
            }
            resolve(false);
          } else {
            resolve(res.data);
          }
        })
    });
  },
  initSession(){
    fromEvent(document, 'click').subscribe(() => {
      sdkPostMessage("platform", "session",  undefined)
    })
  },
  initPreLoginSession(sessionTime?: number, functionOnLogout?:any){
    sdkPostMessage("platform", "preSession",  sessionTime)
    fromEvent(document, 'click').subscribe(() => {
      sdkPostMessage("platform", "session",  undefined)
    })
    sdkPostMessage("platform", "preSessionLogoutFunction", undefined ).then(res => {
      if(functionOnLogout !== undefined){
        functionOnLogout()
      } else {
        sdkPostMessage("platform", "logout",  undefined)
      }
    })
  },
  getPlatformToken(){
    return new Promise((resolve, reject) => {
      sdkPostMessage("platform", "generatePlatformToken",  undefined).then((res) => {
        resolve(res.data);
      }).catch((err) => {
        reject(err);
      });
    })
  },
  renewSession(){
    sdkPostMessage("platform", "session",  undefined)
  },
  isWebOrMobile(){
    return new Promise((resolve, reject) => {
      sdkPostMessage("platform", "isWebOrMobile",  undefined).then((res) => {
        resolve(res.data);
      }).catch((err) => {
          reject(err);
      });
    })
  },
  previousPageUrl(){
    return new Promise((resolve, reject) => {
      sdkPostMessage("platform", "previousPageUrl",  undefined).then((res) => {
        resolve(res.data);
      }).catch((err) => {
        reject(err);
      });
    })
  },
  preFetchData(authRes?: any){
    sdkPostMessage("platform", "preFetchData",  authRes)
  },
  reset(){
    sdkPostMessage("platform", "reset",  undefined)
  },
  setBrowserTile(title: string){
    sdkPostMessage("platform", "browserTitle",  title)
  },
  navigateUrl(url: string | IMenuItem,target?:string) {
    sdkPostMessage("platform", "navigateUrl",  {url: url,target: target})
  },
  navigateAppId(id: string,target?:string) {
    sdkPostMessage("platform", "navigateAppId",  {id: id,target: target})
  },
  printDocument(data:{ body: Blob | MediaSource | string; filename: string}) {
    sdkPostMessage("platform", "printDocument",  data)
  },
  updateUrl(url:string){
    sdkPostMessage("platform", "updateUrl",  {url: url})
  },
  loggedInSuccessfully(authRes: any){
    sdkPostMessage("platform", "loggedInSuccessfully",  authRes)
  },
  updateContext(state: IUserAppStateEntity){
    sdkPostMessage("platform", "update",  state)
  },
  determineJourneyAfterLogin(){
    sdkPostMessage("platform", "determineJourneyAfterLogin",  undefined)
  },
  updateMenuRegion(region: string){
    sdkPostMessage("platform", "updateMenuRegion",  region)
  },
  interactions: {
    update(interactions: IPlatformInteractionsInterface): Promise<boolean> {
      return new Promise((resolve, reject) => {
        sdkPostMessage("platform", "interactions.update",  interactions)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  trackAdobeAnalytics(event:string,action:string){
    sdkPostMessage("platform", "adobe.analytics",  {event: event, action:action})
  },
  logout() {
    sdkPostMessage("platform", "logout",  undefined)
  }
};
